<template>
  <div>
    <el-select
      :class="$style.search"
      v-model="options"
      multiple
      filterable
      default-first-option
      remote
      :remote-method="findSeoText"
      v-el-select-loadmore="loadMoreSeoTexts"
      placeholder="Выберите SEO-тексты"
      @change="$emit('getSeo', options)"
      @remove-tag="deleteSeo($event)"
    >
      <el-option
        v-for="item in seoTextsOptions"
        :key="item.id"
        :label="item.title"
        :value="item.id"
        @click="getSeoTexts()"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import { debounce } from '@/helpers/debounce.js'

export default {
  mixins: [notifications],
  props: {
    seoTexts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: [],
      optionsDefault: [],
      seoTextsOptions: [],
      page: 1,
      limit: 20,
      count: 0,
      isCallSetDefaultOptions: true,
      isWithNotification: true
    }
  },

  async created() {
    await this.getSeoTexts()
    await this.setDefaultOptions()
  },

  watch: {
    async seoTexts() {
      this.isCallSetDefaultOptions && (await this.setDefaultOptions())
    }
  },

  methods: {
    deleteSeo(seoTextId) {
      const isOptionsDefaultHasSeoTextId = this.optionsDefault?.some(
        (option) => option === seoTextId
      )
      if (isOptionsDefaultHasSeoTextId) {
        const seoTextToDelete = this.seoTextsOptions?.find(
          (option) => option.id === seoTextId
        )

        const isConfirm = confirm(
          `Вы точно хотите удалить ${
            seoTextToDelete ? seoTextToDelete.title : 'SEO-текст'
          }`
        )

        isConfirm
          ? this.$emit('deleteSeo', seoTextId)
          : this.options.push(seoTextId)
      }
    },
    async setDefaultOptions() {
      this.options = this.seoTexts

      if (this.seoTexts?.length) {
        if (!this.optionsDefault.length) {
          this.optionsDefault = this.seoTexts
        }

        const seoIds = this.options.join(',')

        const { value, error } =
          await delivery.ContentServiceCore.SeoTextsActions.getByIds(seoIds)

        if (error) {
          this.showNotification(
            'К сожалению, SEO-тексты, которые были добавлены на этой странице - удалены или прикреплены к другой странице, удалите некорректные из них',
            'error'
          )
          this.isCallSetDefaultOptions = false
          return
        }

        let hasDeletedSeoText = false
        value?.forEach((item) => {
          hasDeletedSeoText = this.seoTexts?.every(
            (seoText) => seoText !== item.id
          )
        })

        if (hasDeletedSeoText) {
          this.isWithNotification &&
            this.showNotification(
              'К сожалению, SEO-тексты, которые были добавлены на этой странице - удалены или прикреплены к другой странице, удалите некорректные из них',
              'error'
            )

          this.isWithNotification = false
        }

        value?.forEach((x) => {
          if (!this.seoTextsOptions.find((y) => y.id === x.id)) {
            this.seoTextsOptions.unshift(x)
          }
        })
      }
    },
    loadMoreSeoTexts() {
      if (this.limit * this.page < this.count) {
        this.page++
        const query = {
          page: this.page,
          limit: this.limit
        }
        this.addSeoTexts(query)
      }
    },
    findSeoText(name) {
      debounce(this.searchSeoTexts(name), 500)
    },
    async searchSeoTexts(name) {
      if (name === '') {
        this.page = 1
        this.getSeoTexts()
      }
      if (name.split('').length > 2) {
        const loading = this.$loading({
          lock: true
        })

        const params = {
          limit: 20,
          search: name,
          isLinked: false
        }

        const { value, error } =
          await delivery.ContentServiceCore.SeoTextsActions.getList(params)

        loading.close()

        if (error) return

        this.seoTextsOptions = value.data
        this.count = value.meta.count
      }
    },
    async addSeoTexts(query) {
      const loading = this.$loading({
        lock: true
      })

      const params = {
        page: query?.page || 1,
        limit: query?.limit || 20,
        search: query?.search || null,
        isLinked: false
      }

      const { value, error } =
        await delivery.ContentServiceCore.SeoTextsActions.getList(params)

      loading.close()

      if (error) return

      value.data.forEach((x) => {
        if (!this.seoTextsOptions.find((y) => y.id === x.id))
          this.seoTextsOptions.push(x)
      })
      this.count = value.meta.count
    },
    async getSeoTexts(query) {
      const loading = this.$loading({
        lock: true
      })

      const params = {
        page: query?.page || 1,
        limit: query?.limit || 20,
        search: query?.search || null,
        isLinked: false
      }

      const { value, error } =
        await delivery.ContentServiceCore.SeoTextsActions.getList(params)

      loading.close()

      if (error) return

      this.seoTextsOptions = value.data
      this.count = value.meta.count
    }
  }
}
</script>

<style lang="scss" module>
.search {
  width: 100%;
}
</style>
